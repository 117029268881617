import(/* webpackMode: "eager" */ "/usr/src/packages/BUILD/hedgedoc2/src/hedgedoc-81a9058347a1c4c866b0efdcf9f9000b83b6e6f0/node_modules/next/dist/client/components/app-router.js");
;
import(/* webpackMode: "eager" */ "/usr/src/packages/BUILD/hedgedoc2/src/hedgedoc-81a9058347a1c4c866b0efdcf9f9000b83b6e6f0/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/usr/src/packages/BUILD/hedgedoc2/src/hedgedoc-81a9058347a1c4c866b0efdcf9f9000b83b6e6f0/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/usr/src/packages/BUILD/hedgedoc2/src/hedgedoc-81a9058347a1c4c866b0efdcf9f9000b83b6e6f0/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/usr/src/packages/BUILD/hedgedoc2/src/hedgedoc-81a9058347a1c4c866b0efdcf9f9000b83b6e6f0/node_modules/next/dist/client/components/not-found-boundary.js");
;
import(/* webpackMode: "eager" */ "/usr/src/packages/BUILD/hedgedoc2/src/hedgedoc-81a9058347a1c4c866b0efdcf9f9000b83b6e6f0/node_modules/next/dist/client/components/render-from-template-context.js");
